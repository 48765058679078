.Wrapper {
  @media (--screen--sm-max) {
    padding-bottom: 80px;
  }

  @media (--screen--md-min) {
    padding-bottom: 120px;
  }
}

.Inner {
  position: relative;
  height: 100%;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(188, 188, 188, 0.25);

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 20px;
    box-shadow: 10px 10px 0 #396caa;
    content: "";
  }

  @media (--screen--xs-max) {
    padding-top: 30px;
  }
}

.Row {
  align-items: center;
}

.Image {
  @media (--screen--xs-max) {
    max-width: 400px;
    padding: 0 20px;
    margin: 0 auto;
  }

  @media (--screen--sm-min) and (--screen--md-max) {
    margin-top: -40px;
    margin-left: -60px;
  }

  @media (--screen--lg-min) {
    margin-top: -100px;
    margin-right: -72px;
    margin-left: -110px;
  }
}

.Price {
  font-weight: 500;

  @media (--screen--xs-max) {
    margin-bottom: 30px;
    text-align: center;
  }

  @media (--screen--sm-max) {
    font-size: 30px;
    line-height: 36px;
  }

  @media (--screen--md-min) {
    font-size: 36px;
    line-height: 48px;
  }
}
