:root {
  --rgb--black: 0, 0, 0;
  --rgb--white: 255, 255, 255;
  --color--black: rgb(var(--rgb--black));
  --color--white: rgb(var(--rgb--white));
  --color--text: var(--color--black);
}

@custom-media --screen--xs-max screen and (max-width: 767px);

@custom-media --screen--sm-min screen and (min-width: 768px);
@custom-media --screen--sm-only screen and (min-width: 768px) and (max-width: 1023px);
@custom-media --screen--sm-max screen and (max-width: 1023px);

@custom-media --screen--md-min screen and (min-width: 1024px);
@custom-media --screen--md-only screen and (min-width: 1024px) and (max-width: 1279px);
@custom-media --screen--md-max screen and (max-width: 1279px);

@custom-media --screen--lg-min screen and (min-width: 1280px);
