*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-width: 320px;
  height: 100%;
  padding: 0;
  margin: 0;
  color: var(--color--text);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 700;
}

p {
  margin: 0;
}

a {
  color: currentColor;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

img:not([class]),
svg:not([class]) {
  display: block;
  max-width: 100%;
}

figure {
  margin: 0;
}

.undefined {
  background: red !important;
  color: red !important;
}

.fixed {
  position: fixed;
  width: 100%;
}

.content {
  overflow: hidden;
}

.text {
  + .text {
    margin-top: 20px;
  }
}

.gm-style-pbc {
  display: none !important;
}
